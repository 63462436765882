export const dateParse = (dates: any) => {
  return new Date(`${dates}`).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })
}

export const timeParse = (seconds: number) => {
  return new Date(seconds * 1000).toLocaleTimeString('en-GB', {
    timeZone: 'America/New_York',
    hour12: true,
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
  })
}

// 'long' = full month
// '2-digit' = 2 digit
// 'numeric' = number
