import React from 'react'
import { graphql, Link } from 'gatsby'
import { InView } from 'react-intersection-observer'

import { dateParse } from 'libs/dateParse'

import { useAnimation } from 'template/Animation'
import Related from 'main/Post/Related'
import SEO from 'shared/Seo'
import Layout from 'shared/Layout'
import Paginations from 'shared/Pagination/Paginations'
import { LinkButton } from 'shared/Button'

interface _Datas {
  slug: string
  pageContext: any
  data: {
    takeshape: {
      posts: {
        items: {
          _id: number
          slug: string
          title: string
          date: string
          image: {
            title: string
            path: string
          }
        }[]
      }
    }
  }
}

const s = {
  container: `w-full h-fit mt-60 mx-0 mb-40`,
  wrapper: `wrapper`,
  title: `-mt-4 mb-10 text-4xl`,

  maingrid: `grid-half gap-40 smx:grid-1 smx:gap-20`,
  group: `w-full h-fit flex flex-col mb-20`,

  subgrid: `grid-2 gap-16 mb-28 mdx:grid-1 smx:grid-2 3xsx:grid-`,
  card: `w-full h-fit`,
  link: `block w-full h-[50rem] lgx:h-[45rem] mdx:h-[57rem] smx:h-[49rem] xsx:h-[45rem] 
  2xsx:h-[32rem] 3xsx:h-[35rem] 4xsx:h-[32rem]`,
  image: `w-full h-full object-cover`,

  body: `flex flex-col pt-8`,
  subtitle: `-mt-2 mb-0`,
  date: `mb-12 text-sm`,

  subflex: `w-[37.5rem] h-fit flex flex-col smx:w-full`,
}

const Posts = ({ pageContext, data }: _Datas) => {
  const { animated, fadeInContent, onAnimate } = useAnimation()

  const posts = data.takeshape.posts.items

  return (
    <Layout backgrounds="bg-none">
      <SEO title={`All News`} description={`More somi news page...`} />
      <InView as="div" rootMargin="0px" onChange={onAnimate}>
        <section className={s.container}>
          <div className={s.wrapper}>
            <animated.div className={s.maingrid} style={fadeInContent}>
              <div className={s.group}>
                <h3 className={s.title}>All News</h3>

                <div className={s.subgrid}>
                  {posts.map(({ _id, slug, title, date, image }) => (
                    <div className={s.card} key={_id}>
                      <Link className={s.link} to={`/post/${slug}/`}>
                        <img
                          className={s.image}
                          src={`https://images.takeshape.io/${image.path}?q=70&fit=scale&h=1080&w=1920&fm=webp`}
                          alt={image.title}
                        />
                      </Link>

                      <div className={s.body}>
                        <h3 className={s.subtitle}>{title}</h3>
                        <p className={s.date}>{dateParse(date)}</p>

                        <LinkButton
                          classname="block ml-auto"
                          to={`/post/${slug}/`}
                        >
                          Read News
                        </LinkButton>
                      </div>
                    </div>
                  ))}
                </div>

                <Paginations page={pageContext} />
              </div>

              <div className={s.subflex}>
                <Related show={false} />
              </div>
            </animated.div>
          </div>
        </section>
      </InView>
    </Layout>
  )
}

export default Posts

export const query = graphql`
  query ($from: Int!, $size: Int!) {
    takeshape {
      posts: getPostsList(
        size: $size
        from: $from
        sort: { field: "date", order: "DESC" }
      ) {
        items {
          _id
          slug
          title
          date

          image {
            title
            path
          }
        }
      }
    }
  }
`
